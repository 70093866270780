import { axios } from '@/utils/request'

// TODO 员工管理
export function orgs () {
  return axios({
    url: '/api-user/orgs',
    method: 'get'
  })
}

export function bindWechat (openId) {
  return axios({
    url: '/sys/updateUserOpenId?openId=' + openId,
    method: 'get'
  })
}
