<template>
  <div>
    <div class='infotDetai'>
      <div class='logo'></div>
      <div class='title-cell'>
        <div class='big-title'>江苏南通人力资源服务产业园</div>
        <div class='small-title'>JIANGSU NANTONG HUMAN RESOURCES SERVICE INDUSTRIAL PARK</div>
      </div>
      <input class='input' value='' placeholder='请输入账号' v-model='username' />
      <input class='input' type='password' value='' placeholder='请输入密码' v-model='password' />
      <button class='login' @click='login'>登录</button>
      <p class='loginType'>
        <!-- <router-link class="register" :to="{ name: 'register' }">个人用户注册</router-link> -->
        <router-link class='rest-password' :to="{ name: 'rest-password' }">找回密码</router-link>
      </p>
      <!-- <p class="introduce">企业、入园人力资源服务机构请到PC网站zhaopin516.com注册</p> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Toast } from 'mint-ui' // 引入提示弹窗组件
import { bindWechat } from '@/api/user'

export default {
  data() {
    const { openId } = this.$route.query
    return {
      username: '',
      password: '',
      errText: '',
      openId
    }
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.resolveBack, false) // false阻止默认事件
    }
    // Toast('login-路由层级' + window.history.length)
  },
  destroyed() {
    // 退出页面前取消事件监听
    window.removeEventListener('popstate', this.resolveBack, false)
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    ...mapActions(['Login', 'GetInfo']),
    resolveBack() {
      WeixinJSBridge.call('closeWindow')
    },
    async login() {
      if (!this.username) {
        this.errText = '请输入手机号'
        this.showError()
        return false
      }
      if (!this.password) {
        this.errText = '请输入密码'
        this.showError()
        return false
      } else if (this.password.length < 6) {
        this.errText = '请输入6位以上密码'
        this.showError()
        return false
      }
      const { Login, GetInfo } = this
      const loginParams = {
        username: this.username,
        password: this.password,
        deviceId: '1'
      }
      const that = this
      await Login(loginParams)
      await GetInfo()
      if (this.openId) {
        // this.$store.dispatch('navClick', 'home')
        await bindWechat(this.openId)
      }
      if (that.userInfo.type === 'property') {
        that.$router.replace({
          name: 'property'
        })
      } else {
        Toast('账户类型不匹配！')
      }
    },
    // 弹窗提示
    showError() {
      const instance = Toast({
        message: this.errText,
        position: 'middle',
        duration: 5000
      })
      setTimeout(() => {
        instance.close()
      }, 3500)
    }
  }
}
</script>

<style lang='less' scoped>
.infotDetai {
  height: 100vh;
  padding: 0px 15px;
  text-align: center;
  background: url("~@/assets/img/login_bg.png") no-repeat bottom center;
  background-size: 315.5px 199px;

  .logo {
    height: 150px;
  }

  .title-cell {
    margin-bottom: 50px;

    .big-title {
      font-weight: 700;
      width: 100%;
      font-size: 23px;
    }

    .small-title {
      width: 100%;
      text-align: center;
      font-size: 9px;
      color: #999999;
      margin: 10px 0px;
    }
  }

  img {
    margin-bottom: 20px;
  }

  .input {
    width: 284px;
    height: 42px;
    margin-bottom: 20px;
    background: #f5f5f5;
    line-height: 38px;
    padding-left: 21px;
    box-sizing: border-box;
    color: #333;
    font-size: 15px;
    border-radius: 50px;
    border: 1px solid #f5f5f5;
  }

  .login {
    width: 284px;
    height: 42px;
    background: #178cf9;
    line-height: 38px;
    padding-left: 10px;
    color: #fff;
    font-size: 15px;
    border-radius: 50px;
    border: 1px solid #f5f5f5;
    text-align: center;
    margin-top: 5px;
  }

  .login:link,
  .login:hover,
  .login:visited,
  .login:active,
  .login:focus {
    border-radius: 50px;
  }

  .title {
    margin-top: 40px;

    .line {
      border-bottom: 1px solid #ececec;
      width: 110px;
      display: inline-block;
      vertical-align: middle;
    }

    .or {
      color: #999;
      font-size: 12px;
      margin: 0 10px;
    }
  }

  .loginType {
    margin-top: 20px;
    font-size: 15px;

    .register {
      color: #178cf9;
      margin-right: 80px;
    }

    .rest-password {
      color: #178CF9;
      font-size: 14px;
    }
  }

  .introduce {
    color: #999;
    font-size: 12px;
    margin-top: 20px;
  }
}
</style>
